import React, { useState } from 'react';
import './Galeria.css';

function Galeria() {
  const [temaAtual, setTemaAtual] = useState(null); // Estado para o tema clicado
  const [imagemAtual, setImagemAtual] = useState(null); // Estado para a imagem em tela cheia

  // Dados da galeria organizados por categorias
  const categorias = [
    {
      titulo: 'História e Cultura',
      descricao: 'Descubra o legado histórico e cultural de Paraty, com igrejas coloniais, casarios e festas tradicionais.',
      imagens: [
        { src: '/Igreja_Nossa_Senhora_dos_Remédios,_ou_da_Matriz,_padroeira_da_cidade_de_Paraty.jpg', alt: 'Igreja Matriz Nossa Senhora dos Remédios', titulo: 'Igreja Matriz' },
        { src: 'https://media.istockphoto.com/id/1341851136/pt/foto/street-of-historical-center-in-paraty-rio-de-janeiro-brazil.jpg?s=2048x2048&w=is&k=20&c=joqlVb3xgHkoJa7AiklU3ZmnZMYKt2ufrvCr7IOCflY=', alt: 'Centro histórico de Paraty com ruas de pedras', titulo: 'Centro Histórico' },
        { src: '/paraty-festa.jpg', alt: 'Festa tradicional em Paraty', titulo: 'Festas Típicas' },
      ],
    },
    {
      titulo: 'Natureza e Ecoturismo',
      descricao: 'Explore a beleza natural de Paraty, com cachoeiras, trilhas e paisagens deslumbrantes.',
      imagens: [
        { src: 'https://portoimperial.com.br/blog/wp-content/uploads/2023/04/euamoparaty_lote7_web13052020@fotosincriveis.com_.br-750-768x512.jpg', alt: 'Cachoeira em Paraty cercada pela Mata Atlântica', titulo: 'Cachoeiras' },
        { src: '/paraty-trilha.jpg', alt: 'Trilha em meio à Mata Atlântica em Paraty', titulo: 'Trilhas e Natureza' },
        { src: '/paraty-saco.jpg', alt: 'Vista do Saco do Mamanguá, fiorde tropical em Paraty', titulo: 'Saco do Mamanguá' },
      ],
    },
    {
      titulo: 'Praias e Ilhas',
      descricao: 'Relaxe em praias paradisíacas e explore ilhas deslumbrantes na baía de Paraty.',
      imagens: [
        { src: 'https://imgmd.net/images/v1/guia/1591893/paraty.jpg', alt: 'Praia tranquila em Paraty', titulo: 'Praias de Paraty' },
        { src: 'https://live.staticflickr.com/7457/11074898133_a75a12da87_h.jpg', alt: 'Ilha do Pelado em Paraty', titulo: 'Ilha do Pelado' },
        { src: 'https://paratyadventure.com/wp-content/uploads/2019/08/paraty-1.jpg', alt: 'Barcos na baía de Paraty', titulo: 'Baía de Paraty' },
      ],
    },
    {
      titulo: 'Gastronomia',
      descricao: 'Experimente os sabores autênticos de Paraty, desde frutos do mar frescos até cachaças artesanais.',
      imagens: [
        { src: '/paraty-restaurante.jpg', alt: 'Prato típico servido em Paraty', titulo: 'Pratos Típicos' },
        { src: 'https://blog.portoimperial.com.br/wp-content/uploads/2017/07/paraty_engenhodouro05-1.jpg', alt: 'Alambique de cachaça em Paraty', titulo: 'Cachaças Artesanais' },
        { src: '/paraty-mercado.jpg', alt: 'Mercado de peixes em Paraty', titulo: 'Mercado do Peixe' },
      ],
    },
    {
      titulo: 'Vida Noturna',
      descricao: 'Viva a magia noturna de Paraty com luzes, música ao vivo e o charme do centro histórico.',
      imagens: [
        { src: 'https://imgmd.net/images/c_limit%2Cw_1200/v1/guia/1592544/paraty.jpg', alt: 'Centro histórico de Paraty iluminado à noite', titulo: 'Paraty à Noite' },
        { src: '/paraty-samba.jpg', alt: 'Roda de samba em Paraty', titulo: 'Música e Samba' },
        { src: '/paraty-luzes.jpg', alt: 'Ruas de Paraty com iluminação noturna', titulo: 'Ruas Iluminadas' },
      ],
    },
  ];

  // Função para abrir a pasta temática
  const abrirTema = (tema) => {
    setTemaAtual(tema);
  };

  // Função para fechar o tema
  const fecharTema = () => {
    setTemaAtual(null);
  };

  // Função para abrir o modal de imagem em tela cheia
  const abrirImagem = (imagem) => {
    setImagemAtual(imagem);
  };

  // Função para fechar o modal
  const fecharImagem = () => {
    setImagemAtual(null);
  };

  return (
    <section className="galeria-container">
      <header className="galeria-header">
        <h1>Bem-vindo a Paraty</h1>
        <p>
          Uma curadoria visual rica que destaca a história, natureza, gastronomia e muito mais deste destino encantador.
        </p>
      </header>

      {/* Lista de categorias */}
      {categorias.map((categoria, index) => (
        <div key={index} className="galeria-categoria">
          <h2>{categoria.titulo}</h2>
          <p>{categoria.descricao}</p>
          <div className="galeria-grid">
            {categoria.imagens.slice(0, 1).map((img, idx) => (
              <div
                className="galeria-item"
                key={idx}
                onClick={() => abrirTema(categoria)}
              >
                <img src={img.src} alt={img.alt} />
                <div className="galeria-overlay">
                  <span>{categoria.titulo}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* Modal para exibição do tema selecionado */}
      {temaAtual && (
        <div className="modal" onClick={fecharTema}>
          <div className="modal-content tema-modal">
            <h2>{temaAtual.titulo}</h2>
            <p>{temaAtual.descricao}</p>
            <div className="galeria-grid">
              {temaAtual.imagens.map((img, idx) => (
                <div
                  className="galeria-item"
                  key={idx}
                  onClick={(e) => {
                    e.stopPropagation();
                    abrirImagem(img);
                  }}
                >
                  <img src={img.src} alt={img.alt} />
                  <div className="galeria-overlay">
                    <span>{img.titulo}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Modal de exibição em tela cheia */}
      {imagemAtual && (
        <div className="modal" onClick={fecharImagem}>
          <div className="modal-content">
            <img src={imagemAtual.src} alt={imagemAtual.alt} />
            <span className="fechar-modal" onClick={fecharImagem}>
              &times;
            </span>
          </div>
        </div>
      )}
    </section>
  );
}

export default Galeria;
