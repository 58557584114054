import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    setActiveSubmenu(null); // Fecha submenus ao abrir/fechar menu mobile
  };

  const toggleSubmenu = (submenu) => {
    setActiveSubmenu((prev) => (prev === submenu ? null : submenu)); // Abre/fecha o submenu atual
  };

  const closeSubmenu = () => {
    setActiveSubmenu(null); // Fecha qualquer submenu ativo
  };

  // Fecha os submenus ao clicar fora do menu
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.header')) {
        closeSubmenu();
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <header className="header">
<div className="logo-container">
  <NavLink to="/" onClick={closeSubmenu} className="logo-title">
    Casa Paraty
  </NavLink>
</div>
      <nav>
        <ul className={`menu ${isMobileMenuOpen ? 'menu-mobile' : ''}`}>
          {/* Menu Principal */}
          <li>
            <NavLink to="/" activeClassName="active" exact onClick={closeSubmenu}>
              Home
            </NavLink>
          </li>

          {/* Menu com Submenu: Sobre Paraty */}
          <li className="submenu">
            <button
              onClick={() => toggleSubmenu('sobre-paraty')}
              aria-expanded={activeSubmenu === 'sobre-paraty'}
            >
              Sobre Paraty
            </button>
            <ul className={`submenu-items ${activeSubmenu === 'sobre-paraty' ? 'show' : ''}`}>
              <li>
                <NavLink to="/sobre-paraty/historia" onClick={closeSubmenu}>
                  História
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/sobre-paraty/cultura" onClick={closeSubmenu}>
                  Cultura Local
                </NavLink>
              </li>
              <li>
                <NavLink to="/sobre-paraty/curiosidades" onClick={closeSubmenu}>
                  Curiosidades
                </NavLink>
              </li> */}
            </ul>
          </li>

          {/* Menu com Submenu: Roteiros
          <li className="submenu">
            <button
              onClick={() => toggleSubmenu('roteiros')}
              aria-expanded={activeSubmenu === 'roteiros'}
            >
              Roteiros
            </button>
            <ul className={`submenu-items ${activeSubmenu === 'roteiros' ? 'show' : ''}`}>
              <li>
                <NavLink to="/roteiros/centro-historico" onClick={closeSubmenu}>
                  Centro Histórico
                </NavLink>
              </li>
              <li>
                <NavLink to="/roteiros/praias-e-ilhas" onClick={closeSubmenu}>
                  Praias e Ilhas
                </NavLink>
              </li>
              <li>
                <NavLink to="/roteiros/natureza-trilhas" onClick={closeSubmenu}>
                  Natureza e Trilhas
                </NavLink>
              </li>
            </ul>
          </li> */}

          {/* Menus Simples */}
          {/* <li>
            <NavLink to="/galeria" activeClassName="active" onClick={closeSubmenu}>
              Galeria
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink to="/blog" activeClassName="active" onClick={closeSubmenu}>
              Blog
            </NavLink>
          </li> */}

          {/* Menu com Submenu: Planeje sua Viagem
          <li className="submenu">
            <button
              onClick={() => toggleSubmenu('planeje')}
              aria-expanded={activeSubmenu === 'planeje'}
            >
              Planeje sua Viagem
            </button>
            <ul className={`submenu-items ${activeSubmenu === 'planeje' ? 'show' : ''}`}>
              <li>
                <NavLink to="/planeje/calendario-eventos" onClick={closeSubmenu}>
                  Calendário de Eventos
                </NavLink>
              </li>
              <li>
                <NavLink to="/planeje/onde-ficar" onClick={closeSubmenu}>
                  Onde Ficar
                </NavLink>
              </li>
            </ul>
          </li> */}

          {/* <li>
            <NavLink to="/contato" activeClassName="active" onClick={closeSubmenu}>
              Contato
            </NavLink>
          </li> */}
        </ul>
      </nav>
      <button
        className="menu-toggle"
        onClick={toggleMobileMenu}
        aria-expanded={isMobileMenuOpen}
      >
        ☰
      </button>
    </header>
  );
}

export default Header;
