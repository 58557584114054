import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MapaInterativo.css';
import L from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet.markercluster';

// Corrigir o problema do ícone padrão do Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Lista de pontos turísticos enriquecida
const pontosTuristicos = [
  {
    nome: "Centro Histórico",
    descricao: "A área mais charmosa de Paraty, com ruas de pedra e casarões coloniais.",
    categoria: "Histórico",
    imagem: "https://villadelsol.com.br/wp-content/uploads/2023/05/Depositphotos_313674208_L.jpg",
    coordenadas: [-23.2221, -44.7172],
  },
  {
    nome: "Cachoeira do Tobogã",
    descricao: "Uma cachoeira natural perfeita para escorregar!",
    categoria: "Natureza",
    imagem: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/16/18/41/e0/waterfalls-paraty.jpg?w=1200&h=-1&s=1",
    coordenadas: [-23.2076, -44.7245],
  },
  {
    nome: "Praia do Jabaquara",
    descricao: "Uma praia tranquila e ideal para relaxar.",
    categoria: "Praias",
    imagem: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/07/86/84/3f/praia-de-jabaquara-na.jpg?w=1200&h=-1&s=1",
    coordenadas: [-23.2096, -44.7025],
  },
  {
    nome: "Igreja de Santa Rita",
    descricao: "Construída no século XVIII, é um ícone histórico de Paraty.",
    categoria: "Histórico",
    imagem: "https://estrelatours.com.br/igrejadesantarita03/",
    coordenadas: [-23.2196, -44.7175],
  },
  {
    nome: "Trindade",
    descricao: "Uma vila charmosa com praias paradisíacas.",
    categoria: "Praias",
    imagem: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/ee/81/0c/photo1jpg.jpg?w=1400&h=1400&s=1",
    coordenadas: [-23.3672, -44.7207],
  },
  {
    nome: "Forte Defensor Perpétuo",
    descricao: "Uma construção histórica com vista panorâmica da cidade.",
    endereco: "Av. Orlando Carpinelli, 440 - Pontal, Paraty - RJ, 23970-000",
    telefone: "(24) 98142-0081",
    categoria: "Histórico",
    imagem: "https://euamoparaty.com.br/wp-content/uploads/2018/10/drone@fotosincriveis.com_.br_guidonietmann-18.jpg",
    coordenadas: [-23.2134, -44.7085]
},
  {
    nome: "Praia do Sono",
    descricao: "Uma praia paradisíaca acessível por trilha ou barco.",
    categoria: "Praias",
    imagem: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/45/f3/24/praia-do-sono.jpg?w=1200&h=-1&s=1",
    coordenadas: [-23.3278, -44.5519],
  },
  {
    nome: "Cachoeira da Pedra Branca",
    descricao: "Uma queda d'água exuberante cercada pela Mata Atlântica.",
    categoria: "Natureza",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/06/c1/24/79/cachoeira-pedra-branca.jpg",
    coordenadas: [-23.1993, -44.7458],
  },
  {
    nome: "Casa da Cultura de Paraty",
    descricao: "Um espaço cultural com exposições e eventos sobre a história local.",
    categoria: "Arte e Cultura",
    imagem: "https://static.wixstatic.com/media/bf2ac8_26f0d86c01ee4b27b17ca54c41e9ed21~mv2.png/v1/fill/w_627,h_394,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/LEO-9670%20em%20baixa%20png.png",
    coordenadas: [-23.2205, -44.7182],
  },
  {
    nome: "Cais de Paraty",
    descricao: "Ponto de partida para passeios de barco pelas ilhas e praias próximas.",
    categoria: "Histórico",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/0b/1b/34/8c/cais-de-paraty.jpg",
    coordenadas: [-23.2234, -44.7187],
  },
  {
    nome: "Ilha do Cedro",
    descricao: "Uma ilha encantadora para mergulho e passeio de barco.",
    categoria: "Praias",
    imagem: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/07/07/13/79/ilha-do-cedro.jpg?w=1200&h=-1&s=1",
    coordenadas: [-23.0542, -44.6034],
  },
  {
    nome: "Praia de Paraty-Mirim",
    descricao: "Praia histórica e ponto de partida para passeios de barco.",
    categoria: "Praias",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/09/c3/34/8e/paraty-mirim.jpg",
    coordenadas: [-23.2397, -44.6708],
  },
  {
    nome: "Cachoeira do Tarzan",
    descricao: "Um ótimo lugar para nadar e relaxar em meio à natureza.",
    categoria: "Natureza",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/07/b3/24/78/cachoeira-do-tarzan.jpg",
    coordenadas: [-23.2078, -44.7241],
  },
  {
    nome: "Parque Nacional da Serra da Bocaina",
    descricao: "Uma área protegida com trilhas, cachoeiras e vistas espetaculares.",
    categoria: "Natureza",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/06/a1/34/89/serra-da-bocaina.jpg",
    coordenadas: [-23.1455, -44.7872],
  },
  {
    nome: "Praia Vermelha",
    descricao: "Uma praia isolada e perfeita para quem busca tranquilidade.",
    categoria: "Praias",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/06/c1/34/8f/praia-vermelha.jpg",
    coordenadas: [-23.2961, -44.5738],
  },
  {
    nome: "Restaurante Banana da Terra",
    descricao: "Gastronomia regional com pratos à base de frutos do mar.",
    categoria: "Gastronomia",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/17/23/6c/95/faixada.jpg",
    coordenadas: [-23.2190, -44.7165],
  },
  {
    nome: "Bar do Marquinho",
    descricao: "Uma opção para quem busca vida noturna e boa música em Paraty.",
    categoria: "Vida Noturna",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/0a/b1/34/8e/bar-do-marquinho.jpg",
    coordenadas: [-23.2188, -44.7182],
  },
  {
    nome: "Ateliê Arte e Luz",
    descricao: "Uma galeria com obras de artistas locais.",
    categoria: "Arte e Cultura",
    imagem: "https://media-cdn.tripadvisor.com/media/photo-s/0f/c1/34/89/atelie-arte-e-luz.jpg",
    coordenadas: [-23.2215, -44.7170],
  },
  {
    nome: "Delegacia de Polícia de Paraty",
    descricao: "Delegacia responsável pela segurança pública local.",
    endereço: "Av. Roberto Silveira, s/n - Parque Ypê, Paraty - RJ, 23970-000",
    telefone: "(24) 3371-8484",
    categoria: "Serviços Essenciais",
    imagem: "https://costazul.fm/wp-content/uploads/2021/07/DA234276-8017-485A-A6C4-FBCF434587A3.jpeg", // Adicione uma imagem real ou um placeholder.
    coordenadas: [-23.2185, -44.7192],
  },
  {
    nome: "Hospital Municipal Hugo Miranda",
    descricao: "Atendimento médico de emergência e consultas gerais.",
    categoria: "Serviços Essenciais",
    imagem: "https://i1.wp.com/vivario.org.br/wordpress/wp-content/uploads/2023/05/Hospital-Paraty-82-1024x683.jpg?resize=720%2C480", // Adicione uma imagem real ou um placeholder.
    coordenadas: [-23.2170, -44.7155],
  },

  {
    nome: "Posto de Saúde de Paraty",
    descricao: "Unidade de saúde básica para atendimento médico.",
    categoria: "Serviços Essenciais",
    imagem: "https://s2.glbimg.com/c2RWdfqwJPGNBqZwvEpXwttPqcQ=/s.glbimg.com/jo/g1/f/original/2016/02/10/foto_destaque_materia.jpg", 
    coordenadas: [-23.2170, -44.7155],
  },
  {
    nome: "Rodoviária de Paraty",
    descricao: "Terminal rodoviário que conecta Paraty a diversas cidades da região e do país, oferecendo opções de transporte para turistas e moradores.",
    endereco: "R. Jango Pádua, s/n° - Parque Imperial, Paraty - RJ, 23970-000",
    telefone: "(24) 3371-1224",
    categoria: "Serviços Essenciais",
    imagem: "https://euamoparaty.com.br/wp-content/uploads/paisagens_lote1_alta_17082019_web@fotosincriveis.com_.br-124-600x387.jpg",
    coordenadas: [-23.2172, -44.7154],
  }
  
  
];

// Componente para adicionar o controle de busca
const AddSearchControl = () => {
  const map = useMap();

  useEffect(() => {
    const geocoder = L.Control.geocoder({
      defaultMarkGeocode: false,
    })
      .on('markgeocode', (e) => {
        const { center, name } = e.geocode;
        map.setView(center, 16); // Ajusta o zoom para o local encontrado
        L.marker(center)
          .addTo(map)
          .bindPopup(`<strong>${name}</strong><p>Local encontrado pela busca.</p>`)
          .openPopup();
      })
      .addTo(map);

    return () => map.removeControl(geocoder);
  }, [map]);

  return null;
};

// Componente do mapa interativo
const MapaInterativo = () => {
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("Todas");

  const filtrarPontos = () => {
    if (categoriaSelecionada === "Todas") return pontosTuristicos;
    return pontosTuristicos.filter((ponto) => ponto.categoria === categoriaSelecionada);
  };

  return (
    <>
      <div className="filtros-container">
  <label htmlFor="categoria">Filtrar por Categoria: </label>
  <select
    id="categoria"
    value={categoriaSelecionada}
    onChange={(e) => setCategoriaSelecionada(e.target.value)}
  >
    <option value="Todas">Todas</option>
    <option value="Histórico">Histórico</option>
    <option value="Natureza">Natureza</option>
    <option value="Praias">Praias</option>
    <option value="Gastronomia">Gastronomia</option>
    <option value="Vida Noturna">Vida Noturna</option>
    <option value="Arte e Cultura">Arte e Cultura</option>
    <option value="Serviços Essenciais">Serviços Essenciais</option> {/* Nova categoria */}
  </select>
</div>

      <MapContainer
        center={[-23.2221, -44.7172]} // Coordenadas iniciais (Centro Histórico)
        zoom={13}
        style={{ height: "500px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
        />
        <AddSearchControl />
        {filtrarPontos().map((ponto, index) => (
          <Marker key={index} position={ponto.coordenadas}>
         <Popup>
  <img
    src={ponto.imagem}
    alt={ponto.nome}
    style={{ width: "100%", height: "100px", objectFit: "cover" }}
  />
  <strong>{ponto.nome}</strong>
  <p>{ponto.descricao}</p>
  {ponto.endereco && <p><strong>Endereço:</strong> {ponto.endereco}</p>}
  {ponto.telefone && <p><strong>Telefone:</strong> {ponto.telefone}</p>}
  {/* <p><strong>Categoria:</strong> {ponto.categoria}</p> */}
</Popup>

          </Marker>
        ))}
      </MapContainer>
    </>
  );
};

export default MapaInterativo;