import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header';
// import Hearo from './HeroSection'

import Footer from './Footer';
import PassagensAereas from './Passagem';
import Galeria from './Galeria';
import Pacotes from './Pacotes';
import Hospedagem from './Hospedagem';
import Carros from './Carros';
import Passeios from './Passeios';
import Contato from './Contato';
import Carrossel from './Carrossel';
import Newsletter from './Newsletter';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import SobreParaty from './SobreParaty';
// TODO: Add SDKs for Firebase products that you want to use

const firebaseConfig = {
  apiKey: "AIzaSyB9oClV6EUsYfQatVy1PSztCKjMbE2pBFQ",
  authDomain: "passabrasil-bea91.firebaseapp.com",
  projectId: "passabrasil-bea91",
  storageBucket: "passabrasil-bea91.appspot.com",
  messagingSenderId: "428658173646",
  appId: "1:428658173646:web:b0e0f256ac34006ef9529f",
  measurementId: "G-7NPRT1TE5Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function Home() {
  return (
    <div>
      <img 
        src={`${process.env.PUBLIC_URL}/paraty.jpg`} 
        alt="Paraty" 
        // style={{ width: '100%', height: 'auto' }} 
      />
    </div>
  );
}

function App() {
  return (
    <Router>
      <div>
        <Header />
         {/* <Hearo/>  */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sobre-paraty/historia" element={<SobreParaty />} />
            <Route path="/passagens-aereas" element={<PassagensAereas />} />
            <Route path="/pacotes" element={<Pacotes />} />
            <Route path="/hospedagem" element={<Hospedagem />} />
            <Route path="/carros" element={<Carros />} />
            <Route path="/passeios" element={<Passeios />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/sobre-paraty" element={<SobreParaty />} />
            <Route path="/galeria" element={<Galeria />} />

          </Routes>

        </main>        
        {/* <Footer /> */}
      </div>
    </Router>
  );
}
export default App;