import React from "react";
import './SobreParaty.css';
import MapaInterativo from "./components/mapainterativo";


const SobreParaty = () => {
  return (
    <section className="sobre-paraty-container">
      <div className="sobre-paraty-content">
        <h1 className="sobre-paraty-title">Paraty: Uma Jornada no Tempo pela Costa Verde</h1>
        <p className="sobre-paraty-paragraph">
          Descubra o encanto de uma cidade que guarda séculos de história em cada esquina.
        </p>
        <p className="sobre-paraty-paragraph">
          Paraty, um verdadeiro tesouro da Costa Verde, convida você a uma viagem no tempo. Fundada em 1667, a cidade, cujo nome significa "peixe branco" em tupi-guarani, possui uma rica história marcada pela prosperidade do ciclo do ouro, pela preservação de sua arquitetura colonial e pela exuberante natureza que a cerca.
        </p>

        <h2 className="sobre-paraty-subtitle">Origens e Fundação</h2>
        <p className="sobre-paraty-paragraph">
          Originalmente habitada pelos povos indígenas Guaianás, Paraty foi colonizada no século XVII pelos portugueses, tornando-se um importante entreposto comercial. Sua localização estratégica, entre o mar e a serra, fez dela um ponto essencial no Caminho do Ouro, rota que transportava riquezas das Minas Gerais para o porto.
        </p>

        <h2 className="sobre-paraty-subtitle">O Ciclo do Ouro</h2>
        <p className="sobre-paraty-paragraph">
          No século XVIII, Paraty atingiu o auge econômico como principal porto da região, exportando toneladas de ouro e recebendo mercadorias manufaturadas de Portugal. Suas ruas de pedra, que permanecem até hoje, foram construídas para facilitar o transporte dessas riquezas.
        </p>
        <p className="sobre-paraty-paragraph">
          O declínio veio com novas rotas terrestres que desviaram o fluxo comercial. Paradoxalmente, isso ajudou a preservar sua arquitetura colonial, que se mantém praticamente intacta ao longo dos séculos.
        </p>

        <h2 className="sobre-paraty-subtitle">Cultura e Tradições</h2>
        <p className="sobre-paraty-paragraph">
          A convivência entre indígenas, africanos e europeus resultou em uma rica diversidade cultural em Paraty. Tradições como a Festa do Divino Espírito Santo, realizada desde o século XVIII, mostram essa miscigenação de influências. A cidade também resistiu à invasão de piratas e se destacou na preservação de seu patrimônio.
        </p>

        <h2 className="sobre-paraty-subtitle">Preservação e Patrimônio Mundial</h2>
        <p className="sobre-paraty-paragraph">
          Em 1958, Paraty foi tombada pelo IPHAN, e em 2019, declarada Patrimônio Mundial pela UNESCO. Essa preservação permite que visitantes experimentem uma viagem no tempo, explorando suas ruas de pedra, igrejas históricas e casas coloridas.
        </p>

        <h2 className="sobre-paraty-subtitle">Paraty Hoje</h2>
        <p className="sobre-paraty-paragraph">
          Atualmente, Paraty é um destino turístico que combina história, cultura e natureza. Seu Centro Histórico encanta com casarões coloniais, enquanto eventos como a Festa Literária Internacional de Paraty (FLIP) mostram sua relevância cultural contemporânea.
        </p>
        <p className="sobre-paraty-paragraph">
          Explore trilhas pela Mata Atlântica, praias paradisíacas e saboreie pratos típicos como o peixe com banana da terra. Em Paraty, cada esquina conta uma história e cada sorriso de seus moradores acolhe você de forma única.
        </p>
        <p className="sobre-paraty-paragraph">
         Conheça os principais pontos turísticos Paraty, onde o passado se encontra com o presente em perfeita harmonia.
        </p>
        <MapaInterativo/>
      </div>
    </section>
  );
};

export default SobreParaty;
